import React, { FC, useEffect, useMemo, useState } from 'react';
import { JSONSchema, JSONSchemaDefinition } from 'stores/types/types';
import { CustomSelectProps, Select } from 'components/UI/Select/Select';
import { SelectItemOptionsType } from 'primereact/selectitem';

type SettingsFormSelect = CustomSelectProps & {
  formProperty?: JSONSchema;
  formDefinitions?: { [key: string]: JSONSchemaDefinition };
  parentKeys?: string[];
  formKey: string;
  handleChangeValue: (
    valueKeys: string[],
    value: any,
    isError: boolean,
  ) => void;
};

const PlotSettingsFormSelect: FC<SettingsFormSelect> = (props) => {
  const {
    formProperty,
    parentKeys,
    value,
    formDefinitions,
    handleChangeValue,
    formKey,
  } = props;

  const referenceKeys = formProperty?.$ref?.split('/');
  const referenceKey = referenceKeys?.[referenceKeys.length - 1];
  const referenceDefinition = useMemo(() => {
    if (referenceKey && formDefinitions?.[referenceKey]) {
      return formDefinitions[referenceKey];
    }

    return undefined;
  }, [formDefinitions, referenceKey]);

  const selectOptions: SelectItemOptionsType = useMemo(() => {
    const optionsValues =
      // eslint-disable-next-line
      // @ts-ignore
      (referenceDefinition?.enum as string[] | undefined) || [];

    const optionTitles =
      // eslint-disable-next-line
      // @ts-ignore
      (referenceDefinition?.options?.enum_titles as string[] | undefined) || [];

    return (
      optionsValues.map((el, index) => ({
        value: el,
        label: optionTitles?.[index] || 'n/a',
      })) || []
    );
    // eslint-disable-next-line
    // @ts-ignore
  }, [referenceDefinition?.enum, referenceDefinition?.options?.enum_titles]);

  const [currentValue, setCurrentValue] = useState(
    value || formProperty?.default,
  );

  useEffect(() => {
    setCurrentValue(value || formProperty?.default);
  }, [formProperty?.default, value]);

  return (
    <Select
      value={currentValue}
      options={selectOptions}
      onChange={(e) => {
        setCurrentValue(e.value);
        handleChangeValue([...(parentKeys || []), formKey], e.value, false);
      }}
    />
  );
};

export default PlotSettingsFormSelect;
