import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  ChangeEvent,
} from 'react';
import { observer } from 'mobx-react';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import { ActionMode, DialogOnCloseReason } from 'models/shared';
import styled from 'styled-components';
import Input from 'components/UI/Input';
import Grid from 'components/UI/Grid';
import { PlotFormPropertiesRef } from '../PlotFormProperties';
import PlotSettingsForm from './PlotSettingsForm';

interface PlotSettingsManagementDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const Container = styled(Grid)``;

const StyledDialogContent = styled(DialogContent)`
  padding: 0 1rem;
`;

const PlotSettingsManagementDialog: FC<PlotSettingsManagementDialogProps> =
  observer(({ visible = false, onHide, title, mode }) => {
    const { t } = useTranslation('plot');
    const { t: settingsT } = useTranslation('settings_form');
    const { plotsStore, resellersStore } = useStores();
    const formValuesRef = useRef<any>(null);
    const formCommentRef = useRef<string>('');
    const [commentValid, setCommentValid] = useState(false);

    const [initialUserActionString, setInitialUserActionString] = useState('');

    const [isSaving, setIsSaving] = useState(false);

    const [values, setValues] = useState(plotsStore.plotParams?.params);
    const [errors, setErrors] = useState({});

    const hasError = useMemo(
      () => Object.values(errors).some((error) => error),
      [errors],
    );

    // ValueKey uses a string of keys for the value that changed , for example {item1:{item2:{item3: value}}}
    // returns ['item1', 'item2', 'item3']
    const handleChangeValue = (
      valueKey: string[],
      value: any,
      error: boolean,
    ) => {
      const finalValueKey = valueKey[valueKey.length - 1];
      if (error || Object.keys(errors).includes(finalValueKey)) {
        setErrors((prev) => ({
          ...prev,
          [valueKey[valueKey.length - 1]]: error,
        }));
      }

      const newFormValues = { ...formValuesRef.current };
      let temp = newFormValues;
      for (let i = 0; i < valueKey.length; i += 1) {
        if (i === valueKey.length - 1) {
          temp[valueKey[i]] = value;
        } else {
          temp[valueKey[i]] = { ...temp[valueKey[i]] };
          temp = temp[valueKey[i]];
        }
      }

      formValuesRef.current = temp;
    };

    const handleChangeComment = (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      formCommentRef.current = event.target.value;
      if (event.target.value) {
        setCommentValid(true);
        return;
      }

      setCommentValid(false);
    };

    useEffect(() => {
      if (
        initialUserActionString ||
        JSON.stringify(plotsStore.plotAction) === '{}'
      ) {
        return;
      }

      setInitialUserActionString(JSON.stringify(plotsStore.plotAction));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plotsStore.plotAction]);

    useEffect(() => {
      if (plotsStore.plotParams?.params) {
        setValues(plotsStore.plotParams?.params);
        formValuesRef.current = plotsStore.plotParams?.params;
      }
    }, [plotsStore.plotParams?.params]);

    const closeDialog = useCallback(() => {
      setInitialUserActionString('');
      onHide();
    }, [onHide]);

    const handleCloseDialog = useCallback(
      (event: unknown, reason: DialogOnCloseReason) => {
        if (reason && reason === DialogOnCloseReason.BackDropClick) {
          return;
        }

        closeDialog();
      },
      [closeDialog],
    );

    const handleSubmitClick = useCallback(async () => {
      setIsSaving(true);
      const plotParams = formValuesRef.current;
      const plotId = resellersStore.selectedRowsPlots?.[0]?.id;
      const comments = formCommentRef.current;
      const res = await plotsStore.postPlotParams(plotId, comments, plotParams);
      setIsSaving(false);
      if (res) {
        plotsStore.setPlotParams(undefined);
        closeDialog();
      }
    }, [closeDialog, plotsStore, resellersStore.selectedRowsPlots]);

    return (
      <Dialog
        open={visible}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <StyledDialogContent>
          {plotsStore.plotParams?.schema && values && (
            <>
              <PlotSettingsForm
                handleChangeValue={handleChangeValue}
                properties={plotsStore.plotParams?.schema?.properties}
                definitions={plotsStore.plotParams?.schema?.definitions}
                values={values}
              />
              <Container>
                <Text>{settingsT('comment')}</Text>
                <Input
                  fullWidth
                  multiline
                  error={!commentValid}
                  onChange={handleChangeComment}
                  placeholder={settingsT('comment_description')}
                />
              </Container>
            </>
          )}
        </StyledDialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleSubmitClick}
            disabled={hasError || !commentValid}
            loading={isSaving}
          >
            <Text textcolor={theme.color.white} size="sm">
              {t('edit')}
            </Text>
          </Button>
          <Button onClick={closeDialog}>
            <Text textcolor={theme.color.white} size="sm">
              {t('cancel')}
            </Text>
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

export default PlotSettingsManagementDialog;
