import React, { FC, useState, FocusEvent, useEffect } from 'react';
import { TextField as MuiInput, TextFieldProps } from '@mui/material';
import styled from 'styled-components';

const StyledMuiInput = styled(MuiInput)`
  .Mui-error {
    white-space: pre-line;
  }
`;

export type Validation = (
  text: string,
  field?: string,
) => {
  isValid: boolean;
  message: string;
};

export type InputProps = TextFieldProps & {
  validation?: Validation;
};

const Input: FC<InputProps> = (props) => {
  const {
    error,
    helperText: helperTextProp,
    onBlur: onBlurProp,
    validation,
    ...rest
  } = props;

  const [hasError, setHasError] = useState(error);
  const [helperText, setHelperText] = useState(helperTextProp);
  const onBlur = (
    event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (!event.currentTarget.value) {
      return;
    }

    if (validation) {
      const { isValid, message } = validation(event.currentTarget.value);
      setHasError(!isValid);
      setHelperText(message);
    }

    if (onBlurProp) {
      onBlurProp(event);
    }
  };

  const onFocus = () => {
    setHasError(false);
    setHelperText('');
  };

  useEffect(() => {
    setHasError(error);
  }, [error]);

  useEffect(() => {
    setHelperText(helperTextProp);
  }, [helperTextProp]);

  return (
    <StyledMuiInput
      error={hasError}
      helperText={helperText}
      onFocus={onFocus}
      onBlur={onBlur}
      {...rest}
    />
  );
};

export default Input;
