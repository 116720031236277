import api from 'Api/ApiMethods';
import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import i18next from 'i18next';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { PolygonItem } from 'utils/uploadPolygonsHelpers';
import { getSafeArray } from 'utils/arrayUtils';
import { GrowingType, PhenologicalStage, PlotParams } from 'models/protocol';
import { CropType, ErrorResponse } from 'models/shared';
import { CreatePlotParams } from 'models/api/plot';
import { DEFAULT_COMPANY_OPTIONS, IRRIGATION_METHODS } from 'core/constants';
import {
  ServiceLevel,
  PlotModel,
  PlotManagementValidation,
  WebPlot,
  SoilType,
} from 'models/plot';
import { RootStore } from './rootStore';

export class PlotsStore {
  rootStore?: RootStore = undefined;
  plotAction: PlotModel = {} as PlotModel;
  irrigationMethods: SelectItemOptionsType = [];
  growingType: GrowingType[] = [];
  plotParams: PlotParams | undefined = undefined;
  soilType: SoilType[] = [];
  serviceLevel: ServiceLevel[] = [];
  cropTypes: CropType[] = [];
  varieties: string[] = [];
  categories: string[] = [];
  phenologicalStages: PhenologicalStage[] = [];
  growingMethods: string[] = [];
  selectedPolygon: PolygonItem | undefined;
  plotManagementValidation: PlotManagementValidation = {
    location: false,
  };

  private _plots: WebPlot[] = [];
  private _selectedRows: WebPlot[] = [];
  public get selectedRows(): WebPlot[] {
    return this._selectedRows;
  }

  public set selectedRows(value: WebPlot[]) {
    this._selectedRows = value;
  }

  public get plots(): WebPlot[] {
    return this._plots;
  }

  public set plots(value: WebPlot[]) {
    this._plots = value;
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setSelectedPolygon = (polygon: PolygonItem | null) => {
    this.selectedPolygon = polygon || undefined;
    this.plotAction.geojson = {
      type: 'FeatureCollection',
      features: this.selectedPolygon ? [this.selectedPolygon.value] : null,
    };
  };

  updatePlotStatus = async (status: string) => {
    const plotId = this.selectedRows[0].id;
    // await api.editPlotStatus(plotId, status);
  };

  setPlots = (plots: WebPlot[]) => {
    this.plots = plots;
  };

  setSelectedRows = (plots: WebPlot[]) => {
    this.selectedRows = plots;
  };

  setPlotAction = (plot: PlotModel) => {
    this.plotAction = plot;
  };

  getGrowingType = async () => {
    const res = await api.getGrowingTypes();
    if (res) {
      this.growingType = res;
    }
  };

  getPlotParams = async (plotId: number) => {
    const res = await api.getPlotParams(plotId);
    // eslint-disable-next-line
    // @ts-ignore
    if (res?.schema?.properties?.WaterSeasonalCounter?.id) {
      // eslint-disable-next-line
      // @ts-ignore
      const prevId = res.schema.properties.WaterSeasonalCounter.id;
      // eslint-disable-next-line
      // @ts-ignore
      delete res.schema.properties.WaterSeasonalCounter.id;
      // eslint-disable-next-line
      // @ts-ignore
      res.schema.properties.WaterSeasonalCounter.$id = prevId;
    }

    // eslint-disable-next-line
    // @ts-ignore
    if (res?.schema?.properties?.MeasurementsValidate?.required?.length) {
      // eslint-disable-next-line
      // @ts-ignore
      res.schema.properties.MeasurementsValidate.required = [];
    }

    if (res) {
      this.setPlotParams(res);
    }
  };

  setPlotParams = (params: PlotParams | undefined) => {
    this.plotParams = params;
  };

  postPlotParams = async (
    plotId: number | string,
    comment: string,
    params: PlotParams,
  ) => {
    try {
      const res = await api.postPlotParams(plotId, comment, params);
      return res;
    } catch (e) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(e)
          ? (e.response?.data as ErrorResponse)?.message
          : ``,
      });

      return false;
    }
  };

  resetPlotWaterConsumption = async (
    plotId: number | string,
    growerId: number | string,
  ) => {
    try {
      const res = await api.putResetWaterConsumption(plotId, growerId);
      if (res?.message) {
        this.rootStore?.snackBarStore.showError(res?.message);
      }
    } catch (e) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(e)
          ? (e.response?.data as ErrorResponse)?.message
          : ``,
      });
    }
  };

  getIrrigationMethods = async () => {
    const response = await api.getIrrigationMethods();
    this.irrigationMethods = getSafeArray(response).map((value) => {
      const label = IRRIGATION_METHODS.find(
        (method) => method.value === value,
      )?.label;

      return { value, label: label ?? value };
    });
  };

  getSoilType = async () => {
    const res = await api.getSoilTypes();
    if (res) {
      this.soilType = res;
    }
  };

  getServiceLevel = async () => {
    const res = await api.getServiceLevels();
    if (res) {
      this.serviceLevel = res;
    }
  };

  getCropTypes = async (growerId: number) => {
    const res = await api.getGrowerCropTypes(growerId);
    if (res) {
      this.cropTypes = res;
    }
  };

  getCropTypesGBI = async () => {
    const res = await api.getCropTypes();
    if (res) {
      this.cropTypes = res;
    }
  };

  getCategories = async (growerId: number | null, cropId: number | null) => {
    const res = await api.getCropCategories(growerId, cropId);
    if (res) {
      this.categories = res;
    }
  };

  getVarieties = async (growerId: number | null, cropId: number | null) => {
    const res = await api.getCropVarieties(growerId, cropId);
    if (res) {
      this.varieties = res;
    }
  };

  getprotocolId = async () => {
    try {
      const response = await api.getCropProtocolId(
        this.plotAction.growerId,
        this.plotAction.crop,
        Number(this.plotAction.latitude),
        Number(this.plotAction.longitude),
        this.plotAction.variety,
        this.plotAction.category,
      );

      if (response?.message) {
        this.rootStore?.snackBarStore.showError(response.message);
        return;
      }

      if (!response) {
        this.rootStore?.snackBarStore.showToast({
          detail: `Cannot get protocol id`,
        });
      }

      this.plotAction.cropProtocolId = response.id;
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(error)
          ? (error.response?.data as ErrorResponse)?.message
          : `Cannot get protocol id`,
      });
    }
  };

  getPenologicalStages = async () => {
    const res = await api.getPhenologicalStages(
      this.plotAction.cropProtocolId,
      this.plotAction.plantTime,
      this.plotAction.plotId,
    );

    if (res) {
      this.phenologicalStages = res;
    }
  };

  getGrowingMethod = async () => {
    const res = await api.getGrowingMethods(
      this.plotAction.cropProtocolId,
      this.plotAction.plantTime,
      this.plotAction.plotId,
    );

    if (res) {
      this.growingMethods = res;
    }
  };

  createPlot = async (createPlotParams: CreatePlotParams) => {
    try {
      const plotParams: CreatePlotParams = {
        ...createPlotParams,
        companyUuid: createPlotParams.attachIWSensor
          ? DEFAULT_COMPANY_OPTIONS?.[0].value
          : null,
      };

      const plot = await api.postCreatePlot(
        this.plotAction.growerId,
        this.plotAction,
        plotParams,
      );

      if (plot) {
        this.setSelectedRows([plot]);
        this.setPlots([plot, ...this.plots]);
        this.rootStore?.snackBarStore.showToast({
          detail: `Plot created successfully`,
          severity: 'success',
          summary: 'Success',
        });

        return this.selectedRows.length > 0;
      }

      this.rootStore?.snackBarStore.showToast({
        detail: `Not get plot properties from the server`,
      });
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(error)
          ? (error.response?.data as ErrorResponse)?.message
          : i18next.t<string>('plot:create_plot_error'),
      });

      if (axios.isAxiosError(error)) {
        this.plotManagementValidation.location = (
          error.response?.data as ErrorResponse
        )?.message
          .toUpperCase()
          .includes('LOCATION');
      } else if (error instanceof Error) {
        this.plotManagementValidation.location = error.message
          .toUpperCase()
          .includes('LOCATION');
      }
    }

    return false;
  };

  editPlot = async () => {
    try {
      return await api.putUpdatePlot(
        this.plotAction.growerId,
        this.plotAction.plotId,
        this.plotAction,
      );
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(error)
          ? (error.response?.data as ErrorResponse)?.message
          : i18next.t<string>('plot:edit_plot_error'),
      });
    }

    return null;
  };

  resetCropDetails = (): void => {
    this.categories = [];
    this.varieties = [];
    this.plotAction.variety = '';
    this.plotAction.category = '';
  };

  resetStore = (): void => {
    this.setSelectedPolygon(null);
    this.plotAction = {} as PlotModel;
    this.cropTypes = [];
    this.phenologicalStages = [];
    this.growingMethods = [];
    this.categories = [];
    this.varieties = [];
    this.growingType = [];
    this.irrigationMethods = [];
    this.serviceLevel = [];
    this.soilType = [];
    this.plotManagementValidation.location = false;
  };
}
