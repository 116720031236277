import { InputProps } from 'components/UI/Input/Input';
import { TFunction } from 'i18next';
import { JSONSchema } from 'stores/types/types';

export const checkError = (
  value: string | undefined,
  property: JSONSchema | undefined,
  t: TFunction,
) => {
  if (value == null || property == null) {
    return { message: 'Value is required', isError: true };
  }

  if (property.type === 'number') {
    const numberValue = Number(value);
    if (value == null) {
      return { message: t('value_required'), isError: true };
    }

    if (property.type === 'number') {
      if (Number.isNaN(numberValue)) {
        return { message: t('value_must_be_number'), isError: true };
      }

      if (property.minimum != null && property.minimum > numberValue) {
        return {
          message: t('value_below_minimum', {
            min: property.minimum,
          }),
          isError: true,
        };
      }

      if (property.maximum != null && property.maximum < numberValue) {
        return {
          message: t('value_above_maximum', {
            max: property.maximum,
          }),
          isError: true,
        };
      }

      if (
        !!property?.pattern &&
        new RegExp(`/${property.pattern}/`).test(value)
      ) {
        return { message: t('value_invalid_pattern'), isError: true };
      }
    }
  }

  if (property.type === 'string' && property.pattern) {
    if (!new RegExp(property.pattern).test(value)) {
      return { message: t('value_invalid_pattern'), isError: true };
    }
  }

  return { message: '', isError: false };
};

export type SettingsFormInput = InputProps & {
  formProperty?: JSONSchema;
  parentKeys?: string[];
  formKey: string;
  handleChangeValue: (
    valueKeys: string[],
    value: any,
    isError: boolean,
  ) => void;
};
