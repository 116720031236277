import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import { JSONSchema } from 'stores/types/types';
import { useStores } from 'stores/hooks/hooks';
import Button from 'components/UI/Button';
import Dialog from 'components/UI/Dialog';
import { DialogActions, DialogContent } from '@mui/material';
import Text from 'components/UI/Text';
import { useTranslation } from 'react-i18next';

const Container = styled(Grid)`
  .react-json-view {
    max-height: 35vh;
  }
`;

const WrappingDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0 1rem;
`;

// Note, server needs to update Reset water consumption api to Bot from webfront
const ResetWaterConsumption: FC<{ formProperty?: JSONSchema }> = (props) => {
  const { resellersStore, plotsStore } = useStores();
  const { t } = useTranslation('settings_form');
  const growerId = resellersStore.selectedGrowerId;
  const plotId = resellersStore.selectedRowsPlots?.[0]?.id;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    if (growerId && plotId) {
      await plotsStore.resetPlotWaterConsumption(plotId, growerId);
      setOpen(false);
    }

    setOpen(false);
  };

  return (
    <div>
      <div>{props?.formProperty?.title}</div>
      <WrappingDiv
        key={`object-${props?.formProperty?.$id}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
          border: '1px solid gray',
          borderRadius: 5,
        }}
      >
        <h2>{props?.formProperty?.title}</h2>
        <div>{props?.formProperty?.description}</div>
        <Button variant="outlined" onClick={handleClickOpen}>
          Reset
        </Button>
      </WrappingDiv>
      <Dialog
        open={open}
        onClose={handleClose}
        title={props?.formProperty?.title}
      >
        <StyledDialogContent>
          <Text>{t('reset_water_consumption_validation')}</Text>
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t('reset_water_consumption_cancel')}
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            {t('reset_water_consumption_ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(ResetWaterConsumption);
