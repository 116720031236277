import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import Input, { Validation } from 'components/UI/Input/Input';
import { useTranslation } from 'react-i18next';
import {
  checkError,
  SettingsFormInput,
} from './PlotSettingsManagementDialog.utils';

const PlotSettingsFormInput: FC<SettingsFormInput> = (props) => {
  const { formProperty, parentKeys, value, handleChangeValue, formKey } = props;
  const { t } = useTranslation('settings_form');

  const [currentValue, setCurrentValue] = useState(
    value || formProperty?.default,
  );

  const isError = checkError(
    currentValue as string | undefined,
    formProperty,
    t,
  );

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const checkIfError = checkError(event.target.value, formProperty, t);
    if (formProperty?.type === 'number') {
      setCurrentValue(event.target.value);
      handleChangeValue(
        [...(parentKeys || []), formKey],
        Number(event.target.value),
        checkIfError.isError,
      );
    }

    if (formProperty?.type === 'string') {
      setCurrentValue(event.target.value);
      handleChangeValue(
        [...(parentKeys || []), formKey],
        event.target.value,
        checkIfError.isError,
      );
    }
  };

  const inputValidation: Validation = useCallback(
    (text = '') => {
      const isValid = !isError.isError;
      const errorMessage = `${isError.message}\n${formProperty?.error || ''}`;

      return {
        isValid,
        message: isValid ? '' : errorMessage,
      };
    },
    [formProperty?.error, isError],
  );

  return (
    <Input
      validation={inputValidation}
      label={formProperty?.description}
      id="description"
      disabled={!formProperty?.display}
      value={currentValue}
      // defaultValue={value || formProperty?.default}
      variant="outlined"
      type="text"
      fullWidth
      onChange={handleChange}
    />
  );
};

export default PlotSettingsFormInput;
