import React, { ChangeEvent, FC } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import { JSONSchema, JSONSchemaDefinition } from 'stores/types/types';
import { Checkbox } from '@mui/material';
import Text from 'components/UI/Text';
import PlotSettingsFormSelect from './PlotSettingsFormSelect';
import PlotSettingsFormInput from './PlotSettingsFormInput';
import ResetWaterConsumption from './ResetWaterConsumption';
import PlotSettingsIrrigationCoefficient from './PlotSettingsIrrigationCoefficient';

const StyledText = styled(Text)``;

interface FormComponentProps {
  parentKeys?: string[];
  handleChangeValue: (
    valueKeys: string[],
    value: any,
    isError: boolean,
  ) => void;
  properties: { [key: string]: JSONSchemaDefinition } | undefined;
  definitions: { [key: string]: JSONSchemaDefinition } | undefined;
  values: { [key: string]: any } | undefined;
}
export const PlotSettingsForm: FC<FormComponentProps> = ({
  parentKeys,
  properties,
  definitions,
  values,
  handleChangeValue,
}) => {
  const input = Object.keys(properties || {});

  if (!input?.length) {
    return null;
  }

  return (
    <div>
      {input.map((el) => {
        const property = properties?.[el];
        const value = values?.[el];
        if (!property) {
          return null;
        }

        // Special case- reset water consumption - not a real form input - behavior taken from old web
        if (
          typeof property !== 'boolean' &&
          property.$id === 'watersSeasonalCounter'
        ) {
          return <ResetWaterConsumption formProperty={property} />;
        }

        if (typeof property !== 'boolean' && property.type === 'object') {
          return (
            <div>
              <div>{property?.title}</div>
              <div
                key={`object-${el}`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 10,
                  border: '1px solid gray',
                  borderRadius: 5,
                }}
              >
                <PlotSettingsForm
                  parentKeys={[...(parentKeys || []), el]}
                  properties={property.properties}
                  definitions={definitions}
                  values={value}
                  handleChangeValue={handleChangeValue}
                />
              </div>
            </div>
          );
        }

        if (typeof property !== 'boolean') {
          if (property.$ref) {
            return (
              <Grid
                style={{ marginTop: '1rem' }}
                align="start"
                gap="0.5rem"
                justify="start"
                display="grid"
                grid-template-columns="30% 70%"
              >
                <Text alignSelf="center" weight="bold">
                  {property?.title}
                </Text>

                <PlotSettingsFormSelect
                  formKey={el}
                  handleChangeValue={handleChangeValue}
                  formProperty={property}
                  formDefinitions={definitions}
                  value={value}
                  parentKeys={parentKeys}
                />
              </Grid>
            );
          }

          if (property.type !== 'boolean') {
            return (
              <Grid
                style={{ marginTop: '1rem' }}
                align="start"
                gap="0.5rem"
                justify="start"
                display="grid"
                grid-template-columns="30% 70%"
              >
                <Text alignSelf="center" weight="bold">
                  {property?.title}
                </Text>

                <PlotSettingsFormInput
                  formKey={el}
                  handleChangeValue={handleChangeValue}
                  formProperty={property}
                  value={value}
                  parentKeys={parentKeys}
                />
              </Grid>
            );
          }

          if (property.type === 'boolean') {
            return (
              <Grid
                align="start"
                gap="0.5rem"
                justify="start"
                display="grid"
                grid-template-columns="auto auto"
              >
                <Text alignSelf="center" weight="bold">
                  {property?.title}
                </Text>
                <Checkbox
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChangeValue(
                      [...(parentKeys || []), el],
                      event.target.checked,
                      false,
                    );
                  }}
                  defaultChecked={value}
                />
              </Grid>
            );
          }
        }

        return null;
      })}
      {!parentKeys && (
        <PlotSettingsIrrigationCoefficient
          handleChangeValue={handleChangeValue}
          values={values?.irrigation_coefficient}
        />
      )}
    </div>
  );
};

export default PlotSettingsForm;
