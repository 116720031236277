import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import { useTranslation } from 'react-i18next';
import Text from 'components/UI/Text';
import Input from 'components/UI/Input';
import Space from 'components/UI/Space';
import { Button } from 'primereact/button';

const StyledButtonX = styled(Button)`
  &.p-button {
    background-color: red;
    background: red;
    border: 1px solid red;

    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: red;
    background: red;
    border: 1px solid red;
  }
`;

const StyledButtonPlus = styled(Button)`
  &.p-button {
    width: 20px;
    height: 20px;
  }
`;

interface PlotSettingsIrrigationCoefficientProps {
  values?: { [key: string]: string };
  handleChangeValue: (
    valueKeys: string[],
    value: any,
    isError: boolean,
  ) => void;
}

interface CooefficientValues {
  index: number;
  key: string;
  value: string | undefined;
}

const PlotSettingsIrrigationCoefficient: FC<
  PlotSettingsIrrigationCoefficientProps
> = ({ values, handleChangeValue }) => {
  const { t } = useTranslation('settings_form');

  const valueArray = Object.keys(values || {}).map((key, index) => ({
    index,
    key,
    value: values?.[key],
  }));

  const [coefficientValues, setCoefficientValues] = useState<
    CooefficientValues[]
  >(valueArray || []);

  const handleChangeCoefficientValue = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
      index: number,
    ) => {
      setCoefficientValues((prev) => {
        const temp = [...prev];
        temp[index].value = event.target.value;
        return temp;
      });
    },
    [],
  );

  const handleChangeCoefficientKey = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
      index: number,
    ) => {
      if (event.target.value) {
        setCoefficientValues((prev) => {
          const temp = [...prev];
          temp[index].key = event.target.value;
          return temp;
        });
      }
    },
    [],
  );

  const addNewCoefficient = useCallback(() => {
    setCoefficientValues((prev) => {
      const temp = [...prev];
      temp.push({ index: temp.length, key: '', value: '' });
      return temp;
    });
  }, []);

  const removeCoefficient = useCallback((index: number) => {
    setCoefficientValues((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
  }, []);

  const checkIfCoefficientsAreValid = useCallback(
    (coefficients: CooefficientValues[]) => {
      const isValid = coefficients.every((item) => {
        return item.key !== '' && item.value !== '';
      });

      return isValid;
    },
    [],
  );

  useEffect(() => {
    const isValid = checkIfCoefficientsAreValid(coefficientValues);
    const newValues = coefficientValues.reduce((acc, item) => {
      if (item.key && item.value) {
        (acc as { [key: string]: string })[item.key] = item.value;
      }

      return acc;
    }, {});

    if (isValid) {
      handleChangeValue(['irrigation_coefficient'], newValues, false);
    } else {
      handleChangeValue(['irrigation_coefficient'], newValues, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIfCoefficientsAreValid, coefficientValues]);

  return (
    <Grid direction="column">
      <Space height={1} />
      <Text weight="bold">{t('irrigation_coefficient_title')}</Text>
      <Space height={1} />
      <Grid direction="column">
        {coefficientValues.map((item) => (
          <React.Fragment key={item.index}>
            <Grid direction="row" key={item.index}>
              <Input
                error={item.value === ''}
                type="number"
                value={item.key}
                onChange={(e) => {
                  handleChangeCoefficientKey(e, item.index);
                }}
                label={t('irrigation_coefficient_days')}
              />
              <Space width={1} />
              <Input
                error={item.value === ''}
                type="number"
                value={item.value}
                onChange={(e) => {
                  handleChangeCoefficientValue(e, item.index);
                }}
                label={t('irrigation_coefficient_coefficient')}
              />
              <Space width={1} />
              <StyledButtonX
                icon="fas fa-times"
                aria-label="Cancel"
                onClick={(e) => {
                  removeCoefficient(item.index);
                }}
              />
            </Grid>
            <Space height={1} />
          </React.Fragment>
        ))}
        <StyledButtonPlus
          icon="pi pi-fw pi-plus"
          aria-label="Add"
          onClick={addNewCoefficient}
        />
      </Grid>
    </Grid>
  );
};

export default PlotSettingsIrrigationCoefficient;
